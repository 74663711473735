/* ===== CSS RESET - START ===== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

button {
  border: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* ===== CSS RESET - END ===== */

/* ===== Tailwind ===== */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ===== Global Styles - TODO: Move to Tailwind config file ===== */

:root {
  font-family: Inter, sans-serif;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::marker {
  color: #3371ff;
}

.hs-block {
  border-radius: 0.375rem /* 6px */;
  box-shadow: 0 2px 5px rgba(59, 96, 228, 0.12),
    0 1px 2px rgba(59, 96, 228, 0.24);
}

.block-shadow {
  box-shadow: 0 2px 5px rgba(59, 96, 228, 0.12),
    0 1px 2px rgba(59, 96, 228, 0.24);
}

.videoCard iframe,
.hero-block iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.list li {
  list-style-type: "•";
  padding-left: 10px; /* Adjust the value to your preference */
  margin-bottom: 5px;
  margin-left: 5px;
}

.list li:last-child {
  margin-bottom: 0px; /* Set the margin-bottom to 0 for the last list item */
}

.list a {
  color: #3371ff;
}

.selected {
  color: red;
}

.block-pad-30 {
  padding: 30px;
}

.block-pad-20 {
  padding: 20px;
}

.ql-editor {
  min-height: 200px;
}
